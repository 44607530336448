.newLoginMainWrapper{
    background: url(img/login-bg.png) 100% / 100% no-repeat #e5eefd;
    height : 100vh ;
    display: flex;
    align-items: center;
}
.newLoginWrapper{
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
}
.newLoginContainer{
    width: 90%;
    padding: 25px 0 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.newLoginLeftSectionOuter,
.newLoginRightSectionOuter{
    flex-grow: 1;
    min-height: 500px;
    justify-content: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.newLoginRightSection{
    width: 400px;
    background: #fff;
    padding: 30px;
    border-radius: 15px;
    text-align: left;
    margin: auto;
}

.newLoginLeftTitle{
    font-family: "Helvetica Neue", "Inter", Arial;
    font-size: 24px;
    font-weight: bold;
    margin: 15px 0;
}
.newLoginLeftImgHolder{
    width: 300px;
    margin: auto;
}
.newLoginLeftImgHolder img{
    width: 300px;
}
.newLoginRightSecTitle{
    width: 100%;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    font-family: sans-serif;
    margin-bottom: 25px;
}
.loginBtn{
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 0 20px;
}
.loginBtn span{
    background: #fff;
    padding: 0 10px;
    font-size: 12px;
}
.formgroup {
    margin: 25px 0px;
}
.form-label{
    font-family: sans-serif;
    font-size: 16px !important;
    margin-bottom: 0;
    color: #4a5870 !important;
}
.form-control{
    box-shadow: none !important;
    border-bottom: none !important;
    border: 1px solid #ccc !important;
}
input,
input::placeholder, .form-check-label {
    font:12px sans-serif;
}
.password-input-container {
    position: relative;
    width: 100%;
  }
  
  .password-input-container .form-control {
    padding-right: 40px; /* Add some padding to the right for the icon */
  }
  
  .field-icon {
    position: absolute;
    right: 10px; /* Adjust this value for fine-tuning the icon's position */
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1.2rem; /* Adjust the size of the eye icon */
    color: #4a5870; /* Change this color to match your design */
    z-index: 2; /* Ensure the icon appears above the input field */
  }
  
  .field-icon:hover {
    color: #007bff; /* Optional: change icon color on hover */
  }
  